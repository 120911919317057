import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  public videoItem: any;
  public portfolioItems: any[];
  public categoryItems : any[];
  public categories : any[];

  constructor(public modalRef: BsModalRef) {
    console.log(this.videoItem);
  }
  
  swapVideo(video:any){
    this.videoItem = video
    // console.log(this.categoryItems, 'test')
  }
  ngOnInit() {
    // console.log(this.videoItem);
    console.log(this.portfolioItems);

  }

}
